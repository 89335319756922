import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer-container">
      <p>2024 © MEDICAL EMPOWERMENT DIVISION CENTER</p>
    </footer>
  );
}

export default Footer;
