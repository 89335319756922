import axios from "axios";

const api = axios.create({
  baseURL: "https://medicalemp.id/api",
  // baseURL: "http://localhost:8000/api",
});
// Add a request interceptor to attach the token to the header
// api.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

export default api;
