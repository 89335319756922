import React from "react";
import "./SuperAdminPanel.css";
import { useNavigate } from "react-router-dom";

const SuperAdminPanel = () => {
  const navigate = useNavigate();
  return (
    <div className="superadmin-panel">
      <header className="superadmin-header">
        <h1>SuperAdmin Dashboard</h1>
        <p>Welcome to the control panel</p>
      </header>
      <div className="superadmin-content">
        <div className="grid-container">

            <div className="card">
            <h2>Add Webinar</h2>
            <p>
              Manage webinars, including adding new ones or editing existing
              ones.
            </p>
            <button onClick={() => navigate("/admin/addWebinar")}>
              Manage Webinars
            </button>
          </div>
          
          {/* <div className="card">
            <h2>Manage Users</h2>
            <p>Add, edit, or delete users from the system.</p>
            <button onClick={() => alert("Redirect to User Management")}>
              Manage Users
            </button>
          </div> */}

          <div className="card">
            <h2>Manage Webinars</h2>
            <p>
              Manage webinars, including adding new ones or editing existing
              ones.
            </p>
            <button onClick={() => navigate("/admin/manage-webinars")}>
              Manage Webinars
            </button>
          </div>

          {/* <div className="card">
            <h2>Reports</h2>
            <p>
              View user activity, webinar registrations, and performance
              reports.
            </p>
            <button onClick={() => alert("Redirect to Reports")}>
              View Reports
            </button>
          </div> */}

          {/* <div className="card">
            <h2>Settings</h2>
            <p>
              Configure system-wide settings like roles, permissions, and more.
            </p>
            <button onClick={() => alert("Redirect to Settings")}>
              Settings
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SuperAdminPanel;
