import React, { useState } from "react";
import api from "../../services/api";
import "./AddWebinar.css";

const AddWebinar = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [targetPeserta, setTargetPeserta] = useState([""]);
  const [narasumber, setNarasumber] = useState([""]);
  const [tujuanWebinar, setTujuanWebinar] = useState("");
  const [kompetensi, setKompetensi] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const handleAddPeserta = () => setTargetPeserta([...targetPeserta, ""]);
  const handleRemovePeserta = (index) => {
    const newPeserta = [...targetPeserta];
    newPeserta.splice(index, 1);
    setTargetPeserta(newPeserta);
  };

  const handleAddNarasumber = () => setNarasumber([...narasumber, ""]);
  const handleRemoveNarasumber = (index) => {
    const newNarasumber = [...narasumber];
    newNarasumber.splice(index, 1);
    setNarasumber(newNarasumber);
  };

  const handlePesertaChange = (index, event) => {
    const newPeserta = [...targetPeserta];
    newPeserta[index] = event.target.value;
    setTargetPeserta(newPeserta);
  };

  const handleNarasumberChange = (index, event) => {
    const newNarasumber = [...narasumber];
    newNarasumber[index] = event.target.value;
    setNarasumber(newNarasumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("image", image);

    targetPeserta.forEach((peserta, index) => {
      formData.append(`target_participants[${index}]`, peserta);
    });

    narasumber.forEach((nara, index) => {
      formData.append(`informant[${index}]`, nara);
    });

    formData.append("goals", tujuanWebinar);
    formData.append("competition", kompetensi);
    formData.append("start_time", startTime);
    formData.append("end_time", endTime);

    try {
      await api.post("/admin/webinars", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Webinar created successfully");
    } catch (error) {
      console.error(error);
      alert("Failed to create webinar");
    }
  };

  return (
    <div className="admin-panel">
      <h1>Create Webinar</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div>
          <label>Image (max 10MB)</label>
          <input
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
            accept="image/*"
            required
          />
        </div>
        <div>
          <label>Target Peserta</label>
          {targetPeserta.map((peserta, index) => (
            <div key={index}>
              <input
                type="text"
                value={peserta}
                onChange={(e) => handlePesertaChange(index, e)}
                placeholder="Target Peserta"
                required
              />
              {targetPeserta.length > 1 && (
                <button
                  type="button"
                  onClick={() => handleRemovePeserta(index)}
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <button type="button" onClick={handleAddPeserta}>
            Add Target Peserta
          </button>
        </div>
        <div>
          <label>Narasumber</label>
          {narasumber.map((nara, index) => (
            <div key={index}>
              <input
                type="text"
                value={nara}
                onChange={(e) => handleNarasumberChange(index, e)}
                placeholder="Narasumber"
                required
              />
              {narasumber.length > 1 && (
                <button
                  type="button"
                  onClick={() => handleRemoveNarasumber(index)}
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <button type="button" onClick={handleAddNarasumber}>
            Add Narasumber
          </button>
        </div>
        <div>
          <label>Tujuan Webinar</label>
          <textarea
            value={tujuanWebinar}
            onChange={(e) => setTujuanWebinar(e.target.value)}
            required
          ></textarea>
        </div>
        <div>
          <label>Kompetensi</label>
          <textarea
            value={kompetensi}
            onChange={(e) => setKompetensi(e.target.value)}
            required
          ></textarea>
        </div>
        <div>
          <label>Start Time</label>
          <input
            type="datetime-local"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            required
          />
        </div>
        <div>
          <label>End Time</label>
          <input
            type="datetime-local"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            required
          />
        </div>
        <button type="submit">Create Webinar</button>
      </form>
    </div>
  );
};

export default AddWebinar;
