import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainLayout from "./Layouts/MainLayout";
import AdminLayout from "./Layouts/AdminLayout";
import Home from "./components/Home";
import About from "./components/About";
import UpcomingWebinars from "./components/UpcomingWebinars";
import Register from "./components/Register";
import Login from "./components/Login";
import SuperAdminPanel from "./Pages/Admin/SuperAdminPanel";
import ManageWebinars from "./Pages/Admin/ManageWebinar";
import AddWebinar from "./Pages/Admin/AddWebinar";
import WebinarList from "./components/WebinarList";
import WebinarDetail from "./components/WebinarDetail";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* General routes with Header and Footer */}
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/upcoming-webinars" element={<UpcomingWebinars />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/cek-jadwal" element={<WebinarList />} />
          <Route path="/webinars/:uuid" element={<WebinarDetail />} />
          <Route path="/admin" element={<SuperAdminPanel />} />
        </Route>

        {/* Admin routes without Header and Footer */}
        <Route element={<AdminLayout />}>
          <Route path="/adminPanel" element={<SuperAdminPanel />} />
          <Route path="/admin/addWebinar" element={<AddWebinar />} />
          <Route path="/admin/manage-webinars" element={<ManageWebinars />} />
        </Route>
      </Routes>
    </Router>
  );
};
// app.get("/*", (request, response) => {
//   response.sendFile(path.join(__dirname, "../httpdocs/index.html"));
// });

export default App;
