// src/components/WebinarDetail.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../services/api'; // Assuming you're using the axios instance
import storageUrl from '../services/storage_url'; // Import the storage URL

function WebinarDetail() {
  const { uuid } = useParams(); // Extract the UUID from the URL
  const [webinar, setWebinar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the webinar details from the API using the UUID
    const fetchWebinar = async () => {
      try {
        const response = await api.get(`/webinars/${uuid}`);
        setWebinar(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching webinar');
        setLoading(false);
      }
    };

    fetchWebinar();
  }, [uuid]);

  // Format the date (assuming you have a formatDate function)
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      {webinar ? (
        <div>
          <h2>{webinar.title}</h2>
          <img
            src={`${storageUrl}${webinar.image}`} // Use the storage URL for the image
            alt={webinar.title}
            className="modal-image"
          />
          <p>
            <strong>Description:</strong> {webinar.description}
          </p>
          <p>
            <strong>Goals:</strong> {webinar.goals}
          </p>
          <p>
            <strong>Competition:</strong> {webinar.competition}
          </p>
          <p>
            <strong>Target Participants:</strong>
          </p>
          <ul>
            {webinar.target_participant && webinar.target_participant.map((participant) => (
              <li key={participant.id}>{participant.name}</li>
            ))}
          </ul>
          <p>
            <strong>Informants:</strong>
          </p>
          <ul>
            {webinar.informant && webinar.informant.map((informant) => (
              <li key={informant.id}>{informant.name}</li>
            ))}
          </ul>
          <p>
            <strong>Start Time:</strong> {formatDate(webinar.start_time)}
          </p>
          <p>
            <strong>End Time:</strong> {formatDate(webinar.end_time)}
          </p>
        </div>
      ) : (
        <div>No webinar found</div>
      )}
    </div>
  );
}

export default WebinarDetail;
