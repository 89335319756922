import React, { useState, useEffect } from "react";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import axios from "axios";
import api from "../../services/api";
import storageUrl from "../../services/storage_url";
import "./ManageWebinars.css";

const ManageWebinars = () => {
  const [webinars, setWebinars] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "descending",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedWebinar, setSelectedWebinar] = useState(null); // For selected webinar preview/edit
  const [isModalOpen, setIsModalOpen] = useState(false); // For modal visibility
  const [isEditMode, setIsEditMode] = useState(false); // Toggle between preview and edit
  const [targetParticipants, setTargetParticipants] = useState([]);
  const [informants, setInformants] = useState([]);
  const [imageFile, setImageFile] = useState(null); // For the uploaded image file
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  useEffect(() => {
    const fetchWebinars = async () => {
      try {
        const response = await api.get("/admin/webinars");
        const sortedData = [...response.data].sort((a, b) => b.id - a.id);
        setWebinars(sortedData);
      } catch (error) {
        console.error("Error fetching webinars", error);
      }
    };
    fetchWebinars();
  }, []);

  useEffect(() => {
    if (isEditMode && selectedWebinar) {
      setTargetParticipants(
        selectedWebinar.target_participant.map((p) => p.name)
      );
      setInformants(selectedWebinar.informant.map((i) => i.name));
    }
  }, [isEditMode, selectedWebinar]);

  const sortWebinars = (key) => {
    let direction = "ascending";

    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    setSortConfig({ key, direction });

    setWebinars((prevWebinars) =>
      [...prevWebinars].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      })
    );
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return <FaSort />;
    }
    if (sortConfig.direction === "ascending") {
      return <FaSortUp />;
    }
    return <FaSortDown />;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const time = date.toLocaleTimeString("en-GB");
    return `${day}-${month}-${year}, ${time}`;
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredWebinars = webinars.filter((webinar) =>
    webinar.title.toLowerCase().includes(searchQuery)
  );

  const openModal = (webinar, editMode = false) => {
    setSelectedWebinar(webinar);
    setIsEditMode(editMode);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  // Handle Delete
  const handleDelete = async () => {
    if (!selectedWebinar) return; // Ensure selectedWebinar is defined
    try {
      const response = await api.delete(
        `/admin/webinars/${selectedWebinar.id}` // Use selectedWebinar.id directly
      );
      alert("Webinar deleted successfully!");
      // Optionally refresh your list or update state here
      setWebinars((prevWebinars) =>
        prevWebinars.filter((webinar) => webinar.id !== selectedWebinar.id)
      );
    } catch (error) {
      console.error("Error deleting webinar", error);
      alert("Failed to delete webinar.");
    } finally {
      setIsConfirmOpen(false); // Close the confirmation modal after the action
    }
  };

  const openConfirmation = (webinar) => {
    setIsConfirmOpen(true);
    setSelectedWebinar(webinar); // Set the selected webinar
  };
  // Handle the form submission for the edit mode

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      formData.append("_method", "PUT");

      if (imageFile) {
        formData.append("image", imageFile);
      } else {
        formData.delete("image");
      }
      // Add participants and informants one by one
      targetParticipants.forEach((participant, index) => {
        formData.append(`target_participants[${index}]`, participant);
      });

      informants.forEach((informant, index) => {
        formData.append(`informant[${index}]`, informant);
      });

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }
      const response = await api.post(
        `/admin/webinars/${selectedWebinar.id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
        // { headers: { "Content-Type": "application/json" } }
      );
      alert("Webinar updated successfully!");
      setIsModalOpen(false); // Close the modal after updating
    } catch (error) {
      console.error("Error updating webinar", error);
      alert("Failed to update webinar.");
    }
  };

  // Dynamic input handling
  const handleParticipantChange = (index, value) => {
    const newParticipants = [...targetParticipants];
    newParticipants[index] = value;
    setTargetParticipants(newParticipants);
  };

  const addParticipant = () => {
    setTargetParticipants([...targetParticipants, ""]);
  };

  const removeParticipant = (index) => {
    const newParticipants = targetParticipants.filter((_, i) => i !== index);
    setTargetParticipants(newParticipants);
  };
  // Dynamic input handling for Informants
  const handleInformantChange = (index, value) => {
    const newInformants = [...informants];
    newInformants[index] = value;
    setInformants(newInformants);
  };

  const addInformant = () => {
    setInformants([...informants, ""]);
  };

  const removeInformant = (index) => {
    const newInformants = informants.filter((_, i) => i !== index);
    setInformants(newInformants);
  };

  return (
    <div className="manage-webinars">
      <h2>Manage Webinars</h2>

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search by title"
        value={searchQuery}
        onChange={handleSearch}
        className="search-input"
      />

      <table>
        <thead>
          <tr>
            <th onClick={() => sortWebinars("id")}>ID {getSortIcon("id")}</th>
            <th onClick={() => sortWebinars("title")}>
              Title {getSortIcon("title")}
            </th>
            <th onClick={() => sortWebinars("description")}>
              Description {getSortIcon("description")}
            </th>
            <th onClick={() => sortWebinars("start_time")}>
              Start Time {getSortIcon("start_time")}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredWebinars.map((webinar) => (
            <tr key={webinar.id}>
              <td>{webinar.id}</td>
              <td>{webinar.title}</td>
              <td>{webinar.description}</td>
              <td>{formatDate(webinar.start_time)}</td>
              <td>
                <button onClick={() => openModal(webinar)}>Preview</button>
                <button onClick={() => openModal(webinar, true)}>Edit</button>
                <button onClick={() => openConfirmation(webinar)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isConfirmOpen && (
        <div className="delete-box">
          <p>Are you sure you want to delete this webinar?</p>
          <button onClick={handleDelete}>Yes</button>
          <button onClick={() => setIsConfirmOpen(false)}>Cancel</button>
        </div>
      )}

      {/* Modal for Preview/Edit */}
      {isModalOpen && selectedWebinar && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>

            {isEditMode ? (
              <form onSubmit={handleSubmit}>
                <h2>Edit Webinar</h2>
                <label>
                  Title:
                  <input
                    type="text"
                    name="title"
                    defaultValue={selectedWebinar.title}
                    required
                  />
                </label>
                {selectedWebinar.image && (
                  <img
                    src={`${storageUrl}${selectedWebinar.image}`} // Adjust the image URL accordingly
                    alt={selectedWebinar.title}
                    className="modal-image"
                  />
                )}
                <label>
                  Image:
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files[0]; // Get the selected file
                      setImageFile(file || null); // Set imageFile to null if no file is selected
                    }}
                  />
                </label>
                <label>
                  Description:
                  <textarea
                    name="description"
                    defaultValue={selectedWebinar.description}
                    required
                  />
                </label>
                <label>
                  Goals:
                  <textarea
                    name="goals"
                    defaultValue={selectedWebinar.goals}
                    required
                  />
                </label>
                <label>
                  Competition:
                  <textarea
                    name="competition"
                    defaultValue={selectedWebinar.competition}
                    required
                  />
                </label>
                <label>
                  Start Time:
                  <input
                    type="datetime-local"
                    name="start_time"
                    defaultValue={new Date(selectedWebinar.start_time)
                      .toISOString()
                      .slice(0, 16)}
                    required
                  />
                </label>
                <label>
                  End Time:
                  <input
                    type="datetime-local"
                    name="end_time"
                    defaultValue={new Date(selectedWebinar.end_time)
                      .toISOString()
                      .slice(0, 16)}
                    required
                  />
                </label>

                <label>
                  Target Participants:
                  {targetParticipants.map((participant, index) => (
                    <div key={index}>
                      <input
                        type="text"
                        value={participant}
                        onChange={(e) =>
                          handleParticipantChange(index, e.target.value)
                        }
                        required
                      />
                      <button
                        type="button"
                        onClick={() => removeParticipant(index)}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button type="button" onClick={addParticipant}>
                    Add Participant
                  </button>
                </label>

                <label>
                  Informants:
                  {informants.map((informant, index) => (
                    <div key={index}>
                      <input
                        type="text"
                        value={informant}
                        onChange={(e) =>
                          handleInformantChange(index, e.target.value)
                        }
                        required
                      />
                      <button
                        type="button"
                        onClick={() => removeInformant(index)}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button type="button" onClick={addInformant}>
                    Add Informant
                  </button>
                </label>

                {/* Add the Update button here */}
                <button type="submit" className="update-button">
                  Update Webinar
                </button>
              </form>
            ) : (
              <div>
                <h2>{selectedWebinar.title}</h2>
                <img
                  src={`${storageUrl}${selectedWebinar.image}`} // Adjust the image URL accordingly
                  alt={selectedWebinar.title}
                  className="modal-image"
                />
                <p>
                  <strong>Description:</strong> {selectedWebinar.description}
                </p>
                <p>
                  <strong>Goals:</strong> {selectedWebinar.goals}
                </p>
                <p>
                  <strong>Competition:</strong> {selectedWebinar.competition}
                </p>
                <p>
                  <strong>Target Participants:</strong>
                </p>
                <ul>
                  {selectedWebinar.target_participant.map((participant) => (
                    <li key={participant.id}>{participant.name}</li>
                  ))}
                </ul>
                <p>
                  <strong>Informants:</strong>
                </p>
                <ul>
                  {selectedWebinar.informant.map((informant) => (
                    <li key={informant.id}>{informant.name}</li>
                  ))}
                </ul>
                <p>
                  <strong>Start Time:</strong>{" "}
                  {formatDate(selectedWebinar.start_time)}
                </p>
                <p>
                  <strong>End Time:</strong>{" "}
                  {formatDate(selectedWebinar.end_time)}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageWebinars;
