import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom"; // Import Outlet

const MainLayout = () => {
  return (
    <div>
      <Header />
      <div className="content">
        <Outlet /> {/* This will render the children components */}
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
