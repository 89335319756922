import React from "react";
import { Link } from "react-router-dom";
import "./About.css";

function About() {
  return (
    <div style={{ padding: "50px" }}>
      <h1>Tentang</h1>
      <p>
        Webinar kami terintegrasi dengan sistem point kemenkes. Lorem ipsum
        dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna aliqua.
      </p>

      <h2>Diakui oleh:</h2>
      <ul>
        <li>Kemenkes</li>
        <li>IAKMI</li>
        <li>PPPKMI</li>
      </ul>

      <p>
        Layanan kami tercatat dan terdaftar di kemenkes dan beberapa asosiasi
        tenaga medis lainnya. Ini membuktikan bahwa aktivitas yang kami lakukan
        legal dan diawasi oleh lembaga resmi.
      </p>

      <h2>Lisensi:</h2>
      <p>Tanggal: 11/08/2024</p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>

      <h2>Mitra Kami:</h2>
      <ul>
        <li>Mitra 1</li>
        <li>Mitra 2</li>
        <li>Mitra 3</li>
      </ul>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>
    </div>
  );
}

export default About;
