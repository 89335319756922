import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logomedic.png";
import "./Header.css"; // Custom CSS for Header

function Header() {
  const location = useLocation(); // Get the current path

  return (
    <header className="custom-header">
      <div className="header-container">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <nav className="nav-links">
          <Link
            to="/cek-jadwal"
            className={`nav-item ${location.pathname === "/cek-jadwal" ? "active" : ""}`}
          >
          Cek Jadwal
          </Link>
          <Link
            to="/"
            className={`nav-item ${location.pathname === "/" ? "active" : ""}`}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={`nav-item ${
              location.pathname === "/about" ? "active" : ""
            }`}
          >
            About
          </Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;
