import React from "react";
import { Outlet } from "react-router-dom"; // Import Outlet

const AdminLayout = () => {
  return (
    <div className="admin-layout">
      <Outlet /> {/* This will render the admin panel content */}
    </div>
  );
};

export default AdminLayout;
