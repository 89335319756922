import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Home.css";
import Banner_img from "../assets/images/Banner.png";
import Jadwal_img from "../assets/images/15.png";
import Registrasi_img from "../assets/images/17.png";
import Akses_img from "../assets/images/04.png";
import Goal_img from "../assets/images/aksen_1.png";
import Facebook from "../assets/images/fb.png";
import Instagram from "../assets/images/ig.png";
import TikTok from "../assets/images/tktk.png";
import CardStyle from "../assets/images/taieri.svg";
import logo from "../assets/images/logomedic.png";
import storageUrl from "../services/storage_url"; // Assuming you store the base storage URL here
import api from "../services/api";

function Banner() {
  const [webinars, setWebinars] = useState([]);

  useEffect(() => {
    // Fetching webinars data from API
    api
      .get("/webinars")
      .then((response) => {
        setWebinars(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the webinars!", error);
      });
  }, []);

  return (
    <div className="Homepage">
      <div className="banner-container">
        <div className="banner-content">
          <div className="banner-text">
            <h1 className="banner-title">
              Platform Webinar Terlengkap di Bidang Medis
            </h1>
            <h2 className="banner-subtitle">
              Pantau terus jadwal webinar di platform kami agar tak ketinggalan
            </h2>
            <Link to="/schedule" className="btn-check-schedule">
              Cek Jadwal
            </Link>
          </div>
          <div id="curved-corner-bottomright"></div>{" "}
          {/* This adds the curved corner */}
          <div className="banner-image">
            <img src={Banner_img} alt="Banner" />
          </div>
        </div>
      </div>

      <div className="step-container">
        <h1>Registrasi dengan 3 Langkah Mudah</h1>
        <h3>Anda bisa Menghubungi kami dari berbagai platform sosial media</h3>
        <div className="step-image">
          <div className="step-1">
            <img src={Jadwal_img} alt="Cek Jadwal" />
            <h4> 1. Cek Jadwal</h4>
            <h4 className="description">
              Temukan Webinar yang sesuai dengan kebutuhanmu
            </h4>
          </div>
          <div className="step-2">
            <img src={Registrasi_img} alt="Registrasi" />
            <h4> 2. Registrasi</h4>
            <h4 className="description">Tekan tombol Daftar atau Login</h4>
          </div>
          <div className="step-3">
            <img src={Akses_img} alt="Akses" />
            <h4> 3. Akses Webinar</h4>
            <h4 className="description">
              Anda Akan dihubungi oleh tim kami untuk mendapatkan akses webinar
            </h4>
          </div>
        </div>
      </div>

      <div className="goal-container">
        <div className="goal-image">
          <img src={Goal_img} alt="Tujuan" />
        </div>
        <div className="goal-description">
          <h2>Tujuan Kami</h2>
          <p>
            Kami memfasilitasi tenaga kesehatan & medis untuk menjadi wadah
            berbagi pengetahuan demi lahirnya ahli-ahli penerus dalam bidang
            kesehatan dan medis.
          </p>
          <Link to="/about-us" className="btn-about-us">
            Info Detail
          </Link>
        </div>
      </div>

      <div className="upcoming-webinar">
        <h2>Jadwal Webinar yang akan datang</h2>
        <h3>
          Pantau terus sosial media kami untuk info terupdate pembukaan
          pendaftaran
        </h3>
        <div className="upcoming-webinar">
          <h2>Upcoming Webinars</h2>
          <div className="home-webinar-list">
            {webinars.map((webinar, index) => (
              <div className="webinar-item" key={index}>
                <Link to={`/webinars/${webinar.uuid}`}>
                  <img
                    src={`${storageUrl}/${webinar.image}`}
                    alt={webinar.title}
                    className="webinar-image"
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>

        <div className="card">
          <h2>Temukan kami di sosial media</h2>
          <div className="card-logo">
            <Link className="Facebook">
              <img src={Facebook} alt="Facebook" />
              <p>Facebook</p>
            </Link>
            <Link className="Instagram">
              <img src={Instagram} alt="Instagram" />
              <p>Instagram</p>
            </Link>
            <Link className="TikTok">
              <img src={TikTok} alt="TikTok" />
              <p>TikTok</p>
            </Link>
          </div>
          <img className="card-decoration" src={CardStyle} alt="Decoration" />
        </div>
      </div>

      <div className="landing">
        <div className="business-detail">
          <img src={logo} alt="Medicalemp" />
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
        <div className="business-location">
          <h2>PT. Lorem Ipsum</h2>
          <p>
            Jl. Lorem ipsum No. 01 RT. 01/RW. 02, Kec. Sukmajaya Kota Depok
            123456
          </p>
        </div>
        <div className="business-contact">
          <h2>Kontak Kami</h2>
          <span className="email">contoh@gmail.com</span>
          <span className="phone">(081)8xxx</span>
        </div>
      </div>
    </div>
  );
}

export default Banner;
