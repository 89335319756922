import React from "react";
// import "./UpcomingWebinars.css"; 

function UpcomingWebinars() {
  return (
    <div className="upcoming-webinars-container">
      <h3 className="upcoming-title">Jadwal Webinar Akan Datang</h3>
      <div className="webinars-grid">
        {/* Add your webinar images here */}
        <img
          src="assets/media/illustrations/LAB/img-101.jpg"
          alt="Webinar 1"
          className="webinar-image"
        />
        <img
          src="assets/media/illustrations/LAB/img-102.png"
          alt="Webinar 2"
          className="webinar-image"
        />
        <img
          src="assets/media/illustrations/LAB/img-103.png"
          alt="Webinar 3"
          className="webinar-image"
        />
      </div>
    </div>
  );
}

export default UpcomingWebinars;
