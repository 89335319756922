import "./WebinarList.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../services/api"; // Import your axios instance
import storageUrl from "../services/storage_url"; // Import storage URL

function WebinarList() {
  const [webinars, setWebinars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // 4 items per row, 5 rows = 20 items per page

  useEffect(() => {
    // Fetch the webinars from the API
    const fetchWebinars = async () => {
      try {
        const response = await api.get("/webinars");
        setWebinars(response.data);
        setLoading(false);
      } catch (err) {
        setError("Error fetching webinars");
        setLoading(false);
      }
    };

    fetchWebinars();
  }, []);

  // Pagination logic
  const indexOfLastWebinar = currentPage * itemsPerPage;
  const indexOfFirstWebinar = indexOfLastWebinar - itemsPerPage;
  const currentWebinars = webinars.slice(
    indexOfFirstWebinar,
    indexOfLastWebinar
  );

  // Handle page change
  const nextPage = () => {
    if (currentPage < Math.ceil(webinars.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="webinar-list">
        {currentWebinars.map((webinar, index) => (
          <div className="webinar-card" key={index}>
            <div className="webinar-image-container">
              <Link to={`/webinars/${webinar.uuid}`} className="webinar-link">
                <img
                  src={`${storageUrl}/${webinar.image}`}
                  alt={webinar.title}
                  className="webinar-image"
                />
              </Link>
            </div>
            <div className="webinar-content">
              <h4 className="webinar-title">{webinar.title}</h4>
              <div className="empty-space"></div> {/* Empty space */}
              <Link
                to={`/webinars/${webinar.uuid}`}
                className="webinar-check-button"
              >
                Check
              </Link>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination controls */}
      <div className="pagination">
        <button onClick={prevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button
          onClick={nextPage}
          disabled={currentPage === Math.ceil(webinars.length / itemsPerPage)}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default WebinarList;
